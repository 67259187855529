import React from 'react';
import PropTypes from 'prop-types';

const LabelComponent = ({ text, textStyle, starCount, isrequired = false }) => {
    const stars = [];

    for (let i = 0; i < starCount; i++) {
        stars.push(<span key={i} className="error-message">*</span>);
    }

    return (
        <label className={textStyle}>
            {isrequired && stars}
            {text}
        </label>
    );
};

LabelComponent.propTypes = {
    text: PropTypes.string.isRequired,
    textStyle: PropTypes.string.isRequired,
    starCount: PropTypes.number,
    isrequired: PropTypes.bool
};

export default LabelComponent;
