import React, { createContext, useState, Suspense, useEffect } from 'react';
import './scss/styles.scss';
import { LoaderComponent } from './components';
import { ToastContainer } from 'react-toastify';
import RouteConfig from './RouteConfig';
import 'react-toastify/dist/ReactToastify.css';
import { MsalProvider } from '@azure/msal-react';

export const LoaderContext = createContext();
function App({ instance }) {
  const [isLoading, setIsLoading] = useState(false);
  const [lastActiveTime, setLastActiveTime] = useState(Date.now());

  useEffect(() => {
    const timeout = setTimeout(() => {
      const idleTime = Date.now() - lastActiveTime;
      if (idleTime >= 20 * 60 * 1000) {
        window.location.href = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI;
      }
    }, 20 * 60 * 1000); 

    const handleActivity = () => {
      setLastActiveTime(Date.now());
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('touchstart', handleActivity);
    
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('touchstart', handleActivity);
    };
  }, [lastActiveTime]);

  return (
    <Suspense>
      <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
          <LoaderComponent />
          <ToastContainer />
          <MsalProvider instance={instance}>
            <RouteConfig />
          </MsalProvider>
      </LoaderContext.Provider>
    </Suspense>
  );
}
 
export default App;