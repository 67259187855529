import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const connectionString = process.env.REACT_APP_INSIGHTS;
const appInsights = new ApplicationInsights({
    config: {
        connectionString: connectionString,
    }
});

appInsights.loadAppInsights();

export default appInsights;