export const regexPatterns = {
    Address: /^[0-9a-zA-Z\s,\-:]*$/,
    DriverLicense: /^[A-Z0-9-]+$/,
    ZipCode: /^\d{5}(?:-\d{4})?$/,
    AlphaNumeric: /[^a-zA-Z0-9]/g,
    AccountNumber: /^\d\d{9}$/,
    FourMonthSystemAverage: /^\d*\.?\d+$/,
    Name: /^[a-zA-Z0-9 -]*$/,
    NumbersOnly: /^\d+$/,
    AddProgramNumber: /^(80[1-9]|81[0-3])$/,
    CorrectProgramNumber: /^(80[1-9]|81\d)$/,
    ChangeProgramNumber: /^(813|82\d|83[0-3])$/,
    PricingGroup: /^(?!(00|NC|nc))[a-zA-Z0-9]{2}$/,
    RetailAccessPricingGroup: /^(?!(00|NC|nc))[a-zA-Z0-9]{2,3}$/,
    FlatRatePerTherm: /^(0(\.\d{1,5})?|([1-3](\.\d{1,4})?)|(4(\.0{1,4})?))$/,
    FullPrice: /^(0(\.0{1,8})?|0?\.([0-5]\d{0,7}|50|50.0{0,5})?)$/,
    FixedAdder: /^(-0.0[0-3][0-9]{0,5}|-.0[0-3][0-9]{0,5}|0.0[0-9][0-9]{0,5}|.0[0-9][0-9]{0,5}|0|0.0|0.1[0-0]{0,6}|\.1|\.0)$/
};