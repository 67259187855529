import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ButtonComponent = ({
  children,
  onClick,
  className,
  disabled,
  type,
  style,
  ...restProps
}) => {
  const buttonType = type || 'button';

  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={buttonType}
      style={style}
      {...restProps}
    >
      {children}
    </Button>
  );
};

ButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.any,
  className: PropTypes.string.isRequired,
};

export default ButtonComponent;
