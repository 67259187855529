export const formats = {
    dateFormats:{
        YearFirstWithHifen:'yyyy-MM-dd',
        MonthFirstWithSlash:'MM/dd/yyyy',
        DateFirstWithHifen:'dd-MM-yyyy',
        MonthFirstWithHifen:'MM-dd-yyyy',
        MonthAndYear:'MMM yyyy',
        FullMonthAndYear:'MMMM yyyy',
        OnlyYear:'yyyy'
    },
    momentDateFormats:{
        YearFirstWithHifen:'YYYY-MM-DD',
        MonthFirstWithSlash:'MM/DD/YYYY',
        DateFirstWithHifen:'DD-MM-YYYY',
        MonthFirstWithHifen:'MM-DD-YYYY'
    },
    excelFormat:{
        integer:"0",
        twoDecimalPlaces:"0.00",
        fourDecimalPlaces:"0.0000"
    }
}