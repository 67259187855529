import React, { useContext } from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import { GlobalContext } from "./store/GlobalState";
import {
    dailyReportsCheck, dailyReportsCheckRAFFSubChecks, dailyReportsOBCheck, historyReportsCheck,
    supplierSelectCheck, supplierSelectnaturalGasSubMenuCheck,
    retailerMenuCheck, internalAdministratorMainMenuCheck, internalAdministratorMarketerEnrollmentMenuCheck,
    internalAdministratorMarketerAuditMenuCheck, internalAdministratorAddDropMenuCheck, internalAdministratorExceptionsMenuCheck,
    retailAccessBillingSubmenuCheck, setCusEscoId,
    checkAdminMenuAccess
} from './utils/accessRolesChecks';


const Layout = React.lazy(() => import('./layouts/Layout'));
const EMWLoginPage = React.lazy(() => import('./pages/login-page/emwloginPage'));
const EnergyUseHistory = React.lazy(() => import('./pages/energyusehistory/EnergyUseHistory'));
const HourlyIntervalDataHistory = React.lazy(() => import('./pages/hourly-interval-data-history/HourlyIntervalDataHistory'));
const LightingHistory = React.lazy(() => import('./pages/lighting-history/LightingHistory'));
const Exceptions = React.lazy(() => import('./pages/exceptions/Exceptions'));
const EnrollmentsDropsChanges = React.lazy(() => import('./pages/enrollments-drops-changes/EnrollmentsDropsChanges'));
const DiscontinuedServices = React.lazy(() => import('./pages/discontinued-services/DiscontinuedServices'));
const BillingDeterminants = React.lazy(() => import('./pages/billing-determinants/BillingDeterminants'));
const LighteningBillingDeterminants = React.lazy(() => import('./pages/lighting-bill-determinants/LightingBillDeterminants'));
const OneBillPricing = React.lazy(() => import('./pages/one-bill-pricing/OneBillPricing'));
const EligibleToDrop = React.lazy(() => import('./pages/eligible-to-drop/EligibleToDrop'));
const NaturalGasManualPDF = React.lazy(() => import('./pages/natural-gas-program-manual-pdf/NaturalGasProgramManualPdf'));
const HistoryRequests = React.lazy(() => import('./pages/history-requests/HistoryRequests'));
const DailyReports = React.lazy(() => import('./pages/dailyReports/dailyReports'));
const SupplierSelect = React.lazy(() => import('./pages/supplier-select/supplierSelect'));
const AddPrice = React.lazy(() => import('./pages/supplier-select/addPrice'));
const CorrectPrice = React.lazy(() => import('./pages/supplier-select/correctPrice'));
const RetailSelect = React.lazy(() => import('./pages/retail-select/RetailSelect'));
const RetailChangePrice = React.lazy(() => import('./pages/retail-select/changePrice'));
const RetailCorrectPrice = React.lazy(() => import('./pages/retail-select/correctPrice'));
const MarketerAuditLink = React.lazy(() => import('./pages/marketer-audit-link/MarketerAuditLink'));
const Login = React.lazy(() => import('./pages/login-page/loginPage'));
const PageNotFound = React.lazy(() => import('./pages/page404/Page404-NotFound'));
const MarketerEnrollments = React.lazy(() => import('./pages/marketer-enrollments/MarketerEnrollments'));
const InternalAddNDropReport = React.lazy(() => import('./pages/internal-AddNDropReport/InternalAddNDropReport'));
const InternalExceptions = React.lazy(() => import('./pages/internal-exceptions/InternalExceptions'));
const InternalAdministrator = React.lazy(() => import('./pages/internal-administrator/InternalAdministrator'));
const Admin = React.lazy(() => import('./pages/admin/Admin'));
const Disclaimer = React.lazy(() => import('./pages/disclaimer/Disclaimer'));

const RouteConfig = () => {
    const { state } = useContext(GlobalContext);
    setCusEscoId(state?.userData?.userDetails?.custEscoId);
    return (
        <div>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/pageNotFound" element={<PageNotFound />} />
                <Route path="/" element={<Login />} />
                <Route path="/emwlogin" element={<EMWLoginPage />} />
                <Route path="/disclaimer" element={<Disclaimer />} />

                <Route path="/retails-select" element={state.userData ?
                    <Layout component={retailerMenuCheck(state.userData) ? <RetailSelect /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/retails-select/changeprice" element={state.userData ?
                    <Layout sideMenu="RetailSelectSideMenuLayout" component={retailAccessBillingSubmenuCheck(state.userData) ? <RetailChangePrice /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />

                <Route path="/retails-select/correctprice" element={state.userData ?
                    <Layout sideMenu="RetailSelectSideMenuLayout" component={retailAccessBillingSubmenuCheck(state.userData) ? <RetailCorrectPrice /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                {/* Supplier Select */}
                <Route path="/supplier-select" element={state.userData ?
                    <Layout component={supplierSelectCheck(state.userData) ? <SupplierSelect /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />

                <Route path="/supplier-select/addprice" element={state.userData ?
                    <Layout sideMenu="SupplierSelectSideMenuLayout" component={supplierSelectCheck(state.userData) ? <AddPrice /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />

                <Route path="/supplier-select/correctprice" element={state.userData ?
                    <Layout sideMenu="SupplierSelectSideMenuLayout" component={supplierSelectCheck(state.userData) ? <CorrectPrice /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />

                <Route path="/tsa-manual.pdf" element={state.userData ?
                    <Layout component={(supplierSelectCheck(state.userData) && supplierSelectnaturalGasSubMenuCheck(state.userData)) ? <NaturalGasManualPDF /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                {/* History Request */}
                <Route path="/historyrequests" element={state?.userData ?
                    <Layout component={historyReportsCheck(state?.userData) ? <HistoryRequests /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/energyusehistory" element={state?.userData ?
                    <Layout component={historyReportsCheck(state?.userData) ? <EnergyUseHistory /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/hourlyintervaldatahistory" element={state?.userData ?
                    <Layout component={historyReportsCheck(state?.userData) ? <HourlyIntervalDataHistory /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/lightinghistory" element={state?.userData ?
                    <Layout component={historyReportsCheck(state?.userData) ? <LightingHistory /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />

                {/* Daily Reports */}
                <Route path="/dailyReports" element={state?.userData ?
                    <Layout component={dailyReportsCheck(state?.userData) ? <DailyReports /> : <PageNotFound />} />
                    : <Navigate to='/login' />} />
                <Route path="/billingdeterminants" element={state?.userData ?
                    <Layout component={dailyReportsCheck(state?.userData) ? <BillingDeterminants /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/discontinuedservices" element={state?.userData ?
                    <Layout component={dailyReportsCheck(state?.userData) ? <DiscontinuedServices /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/exceptions" element={state?.userData ?
                    <Layout component={dailyReportsCheck(state?.userData) ? <Exceptions /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/lightingbilldeterminants" element={state?.userData ?
                    <Layout component={(dailyReportsCheck(state?.userData) && dailyReportsCheckRAFFSubChecks(state?.userData)) ? <LighteningBillingDeterminants /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/onebillpricing" element={state?.userData ?
                    <Layout component={(dailyReportsCheck(state?.userData) && dailyReportsOBCheck(state?.userData)) ? <OneBillPricing /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/eligibletodrop" element={state?.userData ?
                    <Layout component={dailyReportsCheck(state?.userData) ? <EligibleToDrop /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/enrollmentsdropschanges" element={state.userData ?
                    <Layout component={dailyReportsCheck(state.userData) ? <EnrollmentsDropsChanges /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />


                <Route path="/marketer-enrollments" element={state?.userData ?
                    <Layout component={internalAdministratorMarketerEnrollmentMenuCheck(state?.userData) ? <MarketerEnrollments /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />
                <Route path="/marketer-audit" element={state?.userData ?
                    <Layout component={internalAdministratorMarketerAuditMenuCheck(state?.userData) ? <MarketerAuditLink /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />

                <Route path="/internal-adddrop" element={state?.userData ?
                    <Layout component={internalAdministratorAddDropMenuCheck(state?.userData) ? <InternalAddNDropReport /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />

                <Route path="/internal-exceptions" element={state?.userData ?
                    <Layout component={internalAdministratorExceptionsMenuCheck(state?.userData) ? <InternalExceptions /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />

                <Route path="/internal-administration" element={state?.userData ?
                    <Layout component={internalAdministratorMainMenuCheck(state?.userData) ? <InternalAdministrator /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />

                <Route path="/admin" element={state?.userData ?
                    <Layout component={checkAdminMenuAccess(state?.userData) ? <Admin /> : <PageNotFound />}
                    /> : <Navigate to='/login' />} />

            </Routes>

        </div>
    );
};

export default RouteConfig;