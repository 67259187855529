import React from 'react';
import { useTable } from 'react-table';
import { regexPatterns } from '../../constants';
import PropTypes from 'prop-types';

function TableComponent({ data, customColumns, headerText, headerClassName, tableClassName, tableHeadingClassName, tableDataClassName, footerClassName, footerText, columnsToFormat, formatSymbol, columnsToSum }) {
  const columns = React.useMemo(
    () => {
      if (!customColumns || customColumns.length === 0) {
        if (!data || data.length === 0) {
          return [];
        }

        const firstDataRow = data[0];
        return Object.keys(firstDataRow).map((key) => ({
          Header: key
            .split(' ')
            .map((word) => {
              return word.replace(regexPatterns.UpperCase, ' $1');
            })
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
          accessor: key,
          Cell: (row) => {
            if (columnsToFormat?.includes(key.toLowerCase())) {
              const formattedValue = Number(row.value).toFixed(2);
              return `${formatSymbol}${formattedValue}`;
            }
            return row.value;
          },
        }));
      }
      else {
        return customColumns;
      }
    }, [data, customColumns, columnsToFormat, formatSymbol]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const balanceSum = React.useMemo(() => {
    if (!Array.isArray(columnsToSum) || columnsToSum.length === 0) {
      return 0;
    }
    return data.reduce((sum, row) => {
      const columnSum = columnsToSum.reduce((colSum, column) => colSum + row[column], 0);
      return sum + columnSum;
    }, 0);
  }, [data, columnsToSum]);

  let formattedBalance;

  if (isNaN(balanceSum) || balanceSum === null) {
    formattedBalance = '0.00';
  } else {
    formattedBalance = formatSymbol ? `${formatSymbol}${balanceSum.toFixed(2)}` : balanceSum.toFixed(2);
  }

  return (
    <div className="table-section">
      <table {...getTableProps()} className={tableClassName}>
        <thead>
          {headerText && (
            <tr>
              <th colSpan={columns.length} className={headerClassName}>
                {headerText}
              </th>
            </tr>
          )}
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className={tableHeadingClassName}{...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={row.index % 2 === 0 ? 'even-row' : 'odd-row'}>
                {row.cells.map((cell) => {
                  return <td className={tableDataClassName}{...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
        {footerText && (
          <tfoot className={footerClassName}>
            <tr>
              <td colSpan={columns.length - 1}>{footerText}</td>
              <td>
                {formattedBalance}
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}

TableComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  customColumns: PropTypes.arrayOf(PropTypes.any).isRequired,
  headerText: PropTypes.string.isRequired,
  headerClassName: PropTypes.string.isRequired,
  tableClassName: PropTypes.string.isRequired,
  tableHeadingClassName: PropTypes.string.isRequired,
  tableDataClassName: PropTypes.string.isRequired,
  footerClassName: PropTypes.string.isRequired,
  footerText: PropTypes.string.isRequired,
  columnsToFormat: PropTypes.arrayOf(PropTypes.any).isRequired,
  formatSymbol: PropTypes.string.isRequired,
  columnsToSum: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default TableComponent;
