import { labelConstants } from '../constants';
// Roles based access checks for all routes
// consts

const roles = {
  FF_: 'FF_',
  RA_: 'RA_',
  _HI: '_HI',
  _EN: '_EN',
  RA_EN: 'RA_EN',
  FF_EN: 'FF_EN',
  _OB: '_OB',
  RA_HI: 'RA_HI',
  SS_: 'SS_',
  SS_EN: 'SS_EN',
  SS_OB: 'SS_OB',
  SS_IR: 'SS_IR',
  SS_ST: 'SS_ST',
  AD_ST: 'AD_ST',
  RA_MM: 'RA_MM',
  RA_OB: 'RA_OB',
  AD_: 'AD_',
  AD_AU: 'AD_AU',
  AD_DR: 'AD_DR',
  AD_EX: 'AD_EX',
  AD_EA: 'AD_EA',
};

const returnIncludesCheck = (rolesList, checkPoint) => {
  return rolesList?.some((item) => item.includes(checkPoint));
};

let custEscoId = "";

export const setCusEscoId=(escoId)=>{
  custEscoId=escoId;
}

export const getCusEscoId=()=>{
 return custEscoId;
}

// A part from _HI
export const dailyReportsCheck = (userData) => {
  let _HiCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    _HiCheck = returnIncludesCheck(userData.roleList, roles._HI);
    return (
      returnIncludesCheck(userData.roleList, roles._EN) && userGlobalStatus
    );
  }
  return _HiCheck;
};

// A part from _HI and lighting bill determines
export const dailyReportsCheckRAFFSubChecks = (userData) => {
  let rAFfCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    rAFfCheck =
      returnIncludesCheck(userData.roleList, roles.RA_EN) ||
      returnIncludesCheck(userData.roleList, roles.FF_EN);
    return rAFfCheck && userGlobalStatus;
  }
  return rAFfCheck;
};

// A part from _HI and One bill price
export const dailyReportsOBCheck = (userData) => {
  let oBCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    return (
      returnIncludesCheck(userData.roleList, roles._OB) && userGlobalStatus
    );
  }
  return oBCheck;
};

export const historyReportsCheck = (userData) => {
  //  (admin_id = ““)  Need to verify
  const adminCheck = userData.adminId ? false : true;
  let historyTabCheck = false;
  if (userData) {
    const userOneType = userData?.userDetails?.userType === '1';
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    historyTabCheck =
      returnIncludesCheck(userData.roleList, roles._HI) ||
      returnIncludesCheck(userData.roleList, roles._EN);
    return historyTabCheck && userGlobalStatus && userOneType && adminCheck;
  }
  return historyTabCheck;
};

export const historyAccordionsSectionsCheck = (userData) => {
  let historyAccordionsCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    const userOneType = userData?.userDetails?.userType === '1';
    historyAccordionsCheck =
      returnIncludesCheck(userData.roleList, roles.FF_EN) ||
      returnIncludesCheck(userData.roleList, roles.RA_EN);
    return historyAccordionsCheck && userGlobalStatus && userOneType;
  }
  return historyAccordionsCheck;
};

// Show main menu Supplier select.
export const supplierSelectCheck = (userData) => {
  let supplierMainMenucheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    supplierMainMenucheck =
      returnIncludesCheck(userData.roleList, roles.SS_) &&
      !returnIncludesCheck(userData.roleList, roles._HI);
    return supplierMainMenucheck && userGlobalStatus;
  }
  return supplierMainMenucheck;
};

// Show Supplier select sub menu Natural Gas Program Manual
export const supplierSelectnaturalGasSubMenuCheck = (userData) => {
  let supplierSelectnaturalGasMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    supplierSelectnaturalGasMenuCheck =
      returnIncludesCheck(userData.roleList, roles.SS_) &&
      !returnIncludesCheck(userData.roleList, roles._HI);
    return supplierSelectnaturalGasMenuCheck && userGlobalStatus;
  }
  return supplierSelectnaturalGasMenuCheck;
};

// Show Supplier select sub menu Billing Maintenance
export const supplierSelectBillingSubMenuCheck = (userData) => {
  let supplierSelectBillingMenuCheck = false;
  if (userData) {
    const adminCheck = userData.adminId ? false : true;
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    const userOneType = userData?.userDetails?.userType === '1';
    supplierSelectBillingMenuCheck =
      returnIncludesCheck(userData.roleList, roles.SS_EN) &&
      adminCheck &&
      returnIncludesCheck(userData.roleList, roles.SS_OB) &&
      userOneType;
    return supplierSelectBillingMenuCheck && userGlobalStatus;
  }
  return supplierSelectBillingMenuCheck;
};

// Show Supplier select sub menu Interruptible Rates
export const supplierSelectInterruptibleSubMenuCheck = (userData) => {
  let supplierSelectInterruptibleMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    return (
      returnIncludesCheck(userData.roleList, roles.SS_IR) && userGlobalStatus
    );
  }
  return supplierSelectInterruptibleMenuCheck;
};

// Show Supplier select sub menu Transportation Services Applications

export const supplierSelectTransportationSubMenuCheck = (userData) => {
  let supplierSelectTransportationMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    supplierSelectTransportationMenuCheck =
      returnIncludesCheck(userData.roleList, roles.SS_ST) ||
      returnIncludesCheck(userData.roleList, roles.AD_ST);
    return supplierSelectTransportationMenuCheck && userGlobalStatus;
  }
  return supplierSelectTransportationMenuCheck;
};

// Show main menu Retail Access
// Show sub menu 'Unaccounted for Energy' Statistics’  & sub menu Ancillary Service Charges

export const retailerMenuCheck = (userData) => {
  let retailerMainMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    retailerMainMenuCheck =
      returnIncludesCheck(userData.roleList, roles.FF_EN) ||
      returnIncludesCheck(userData.roleList, roles.RA_EN) ||
      returnIncludesCheck(userData.roleList, roles.RA_MM);
    return retailerMainMenuCheck && userGlobalStatus;
  }
  return retailerMainMenuCheck;
};

// Display text - Information entered after 5:55 p.m. will have the next day's insert date.
export const informationEnteredafterFiveCheck = (userData) => {
  let informationEnteredafterFiveSubMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    informationEnteredafterFiveSubMenuCheck =
      returnIncludesCheck(userData.roleList, roles.FF_EN) ||
      returnIncludesCheck(userData.roleList, roles.RA_EN);
    return informationEnteredafterFiveSubMenuCheck && userGlobalStatus;
  }
  return informationEnteredafterFiveSubMenuCheck;
};

// Display Retail Access sub menu Billing Maintenance
export const retailAccessBillingSubmenuCheck = (userData) => {
  let retailAccessBillingMenuCheck = false;
  if (userData) {
    const adminCheck = userData.adminId ? false : true;
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    const usertypeOneStatus = userData?.userDetails?.userType === '1';
    retailAccessBillingMenuCheck =
      usertypeOneStatus &&
      returnIncludesCheck(userData.roleList, roles.RA_OB) &&
      adminCheck;
    return retailAccessBillingMenuCheck && userGlobalStatus;
  }
  return retailAccessBillingMenuCheck;
};
// Display Retail Access sub menu Account Verification Report
export const retailAccessVerificationSubmenuCheck = (userData) => {
  let retailAccessVerificationMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    //const usertypeOneStatus = userData?.userDetails?.userType === '1';
    retailAccessVerificationMenuCheck =
      (returnIncludesCheck(userData.roleList, roles.FF_EN) ||
        returnIncludesCheck(userData.roleList, roles.RA_EN));
    return retailAccessVerificationMenuCheck && userGlobalStatus;
  }
  return retailAccessVerificationMenuCheck;
};

// Internal Administrator

// Display main menu Internal Administrator
export const internalAdministratorMainMenuCheck = (userData) => {
  let internalAdministratorMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    return returnIncludesCheck(userData.roleList, roles.AD_) && userGlobalStatus;
  }
  return internalAdministratorMenuCheck;
};

// Display Internal Administrator sub menu Marketer Audit
export const internalAdministratorMarketerAuditMenuCheck = (userData) => {
  let internalAdministratorSubMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    return returnIncludesCheck(userData.roleList, roles.AD_AU) && userGlobalStatus;
  }
  return internalAdministratorSubMenuCheck;
};

// Display Internal Administrator sub menu Internal Add drop
export const internalAdministratorAddDropMenuCheck = (userData) => {
  let internalAdministratorSubMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    return returnIncludesCheck(userData.roleList, roles.AD_DR) && userGlobalStatus;
  }
  return internalAdministratorSubMenuCheck;
};

// Display Internal Administrator sub menu Internal Exceptions
export const internalAdministratorExceptionsMenuCheck = (userData) => {
  let internalAdministratorSubMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    return returnIncludesCheck(userData.roleList, roles.AD_EX) && userGlobalStatus;
  }
  return internalAdministratorSubMenuCheck;
};

// Display Internal Administrator sub menu Internal Marketer Enrollment
export const internalAdministratorMarketerEnrollmentMenuCheck = (userData) => {
  let internalAdministratorSubMenuCheck = false;
  if (userData) {
    const userGlobalStatus = userData?.userDetails?.userStatus === 'A';
    return returnIncludesCheck(userData.roleList, roles.AD_EA) && userGlobalStatus;
  }
  return internalAdministratorSubMenuCheck;
};
export const routeTovalidpage = (data) => {
  if (historyReportsCheck(data)) {
    return '/historyrequests';
  } else if (dailyReportsCheck(data)) {
    return '/dailyReports';
  } else if (supplierSelectCheck(data)) {
    return '/supplier-select';
  } else if (retailerMenuCheck(data)) {
    return '/retails-select';
  } else if (internalAdministratorMainMenuCheck(data)) {
    return '/internal-administration';
  } else if (checkAdminMenuAccess(data)) {
    return '/admin';
  }
}

export const getRoleBasedDownloadDataForOptions = (userData) => {
  const { downloadDataForOptions } = labelConstants;
  const roleList = userData?.roleList || [];

  let gas_elec_cnt = 0;
  if (roleList.some(item => item.includes(roles.SS_)) 
      || roleList.includes(roles.AD_DR) 
      || roleList.includes(roles.AD_EX)) {
    gas_elec_cnt = 1;
  }
  if (roleList.some(item => item.includes(roles.FF_)) 
      || roleList.some(item => item.includes(roles.RA_)) 
      || roleList.includes(roles.AD_DR) 
      || roleList.includes(roles.AD_EX)) {
    gas_elec_cnt = gas_elec_cnt + 2;
  }

  if (gas_elec_cnt === 1) {
    return { defaultOption: '2', options: [{ label: 'Gas', value: '2' }] };
  } else if (gas_elec_cnt === 2) {
    return { defaultOption: '1', options: [{ label: 'Electric', value: '1' }] };
  } else {
    return { defaultOption: '3', options: downloadDataForOptions };
  }
};

export const checkAdminMenuAccess = (userData) => {
  const roleList = userData?.roleList || [];
  return roleList.includes('ADMIN');
}

export const checkAdminFormAccess = (userData, isAdminPath = '') => {
  const roleList = userData?.roleList || [];
  return roleList.includes('ADMIN') && isAdminPath === '/admin';
}
