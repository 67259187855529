import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const DropDownComponent = ({ options, selectedOption, onChange, dropDownStyle, isMultiSelectionEnabled = false,
    placeholder = '', isRequired = false, errorType = 0, isDisabled = false }) => {

    const handleChange = (selectedOption) => {
        onChange(selectedOption);
    };

    const selectOptions = options.map((option) => ({
        value: option.value,
        label: option.label,
    }))
        .sort((a, b) => a.label.localeCompare(b.label));

    let updatedClassName = dropDownStyle;
    if (errorType === 1 || errorType === 3) {
        updatedClassName = 'red-borderDropdown';
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: 0, // Set the border radius to 0px
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            backgroundColor: state.isSelected ? '#00148c' : 'transparent',
            color: state.isSelected ? '#ffffff' : '#53565a',
            borderBottom: '2px solid #ccc',
            fontWeight: '700',
            '&:hover': {
                backgroundColor: '#00148c', // Change background color on hover
                color: '#ffffff'
            }
        }),
    };

    return (
        <div>
            <Select
                isMulti={isMultiSelectionEnabled}
                value={selectedOption}
                onChange={handleChange}
                options={selectOptions}
                placeholder={placeholder}
                required={isRequired}
                onKeyDown={handleKeyDown}
                className={updatedClassName}
                isSearchable={false}
                styles={customStyles}
                isDisabled={isDisabled}
            />
        </div>
    );
};

DropDownComponent.propTypes = {
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    errorType: PropTypes.number,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    isMultiSelectionEnabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    dropDownStyle: PropTypes.string,
    selectedOption: PropTypes.any,
};

export default DropDownComponent;
