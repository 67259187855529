import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { formats, regexPatterns } from '../../constants';
import { labelConstants } from '../../constants/label-constants';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const { errorMessages, calenderStartDate } = labelConstants;
const { dateFormats } = formats;
const CalenderComponent = ({
  selectedDate,
  onChange,
  returnDateFormat = null,
  checkValidStatus = null,
  placeholderText = dateFormats.MonthFirstWithSlash,
  minDate = new Date(calenderStartDate),
  maxDate = new Date(),
  dateFormat = dateFormats.MonthFirstWithSlash,
  dateOptFormat = dateFormats.DateFirstWithSlash,
  isClearable = false,
  showYearDropdown = true,
  showMonthDropdown = true,
  errorType = 0,
  className = '',
  ...restProps
}) => {
  const handleSelect = (e) => {
    const inputDate = e;
    const momentDateFormat = dateOptFormat.replace(regexPatterns.AlphaNumeric, '');
    if (inputDate) {
      const formattedOpt = moment(inputDate, momentDateFormat.toUpperCase()).format(dateOptFormat.toUpperCase());
      const formattedDateValid = moment(formattedOpt, dateOptFormat.toUpperCase()).isValid();
      if (formattedDateValid) {
        if (returnDateFormat) {
          returnDateFormat(formattedOpt);
        }
        if (checkValidStatus) {
          checkValidStatus(errorMessages.valid)
        }
      }
      else {
        if (returnDateFormat) {
          returnDateFormat(null);
        }
        if (checkValidStatus) {
          checkValidStatus(errorMessages.inValid)
        }
      }
    } else {
      if (returnDateFormat) {
        returnDateFormat(null);
      }
      if (checkValidStatus) {
        checkValidStatus(errorMessages.inValid)
      }
    }
  }
  const handleBlur = (e) => {
    const inputDate = e.target.value;
    if (inputDate) {
      const numericDate = inputDate.replace(/\D/g, '');
      const momentDateFormat = dateFormat.replace(/[^a-zA-Z0-9]/g, ''); // Update to the correct variable if needed
      if (momentDateFormat) {
        if (numericDate.length === momentDateFormat.length) {
          const formatted = moment(numericDate, momentDateFormat.toUpperCase()).format(dateFormat.toUpperCase());
          const formattedOpt = moment(numericDate, momentDateFormat.toUpperCase()).format(dateOptFormat.toUpperCase());
          const formattedDate = moment(formatted, dateFormat.toUpperCase());
          if (formattedDate.isValid() && formattedDate.isSameOrAfter(minDate) && formattedDate.isSameOrBefore(maxDate)) {
            if (restProps.includeDates && restProps.includeDates.length > 0) {
              const included = restProps.includeDates.some((date) =>
                moment(date).isSame(formattedDate, 'day')
              );
              if (!included) {
                onChange(null);
                if (returnDateFormat) {
                  returnDateFormat(null);
                }
                if (checkValidStatus) {
                  checkValidStatus(errorMessages.inValid);
                }
                return;
              }
            }
            onChange(moment(formatted, dateFormat.toUpperCase()).toDate());
            if (returnDateFormat) {
              returnDateFormat(formattedOpt);
            }
            if (checkValidStatus) {
              checkValidStatus(errorMessages.valid);
            }
          } else {
            onChange(null);
            if (returnDateFormat) {
              returnDateFormat(null);
            }
            if (checkValidStatus) {
              checkValidStatus(errorMessages.inValid);
            }
          }
        } else {
          onChange(null);
          if (returnDateFormat) {
            returnDateFormat(null);
          }
          if (checkValidStatus) {
            checkValidStatus(errorMessages.inValid);
          }
        }
      }
    }
  };

  let updatedClassName = '';

  if (errorType === 1 || errorType === 3) {
    updatedClassName = 'red-border';
  }
  else {
    updatedClassName = 'input-text';
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  return (
    <DatePicker
      showIcon
      selected={selectedDate}
      className={updatedClassName}
      onChange={onChange}
      onBlur={handleBlur}
      onSelect={handleSelect}
      onChangeRaw={handleBlur}
      onKeyDown={handleKeyDown}
      placeholderText={placeholderText}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={dateFormat}
      isClearable={isClearable}
      showYearDropdown={showYearDropdown}
      showMonthDropdown={showMonthDropdown}
      strictParsing
      customInput={<InputMask mask="99/99/9999" placeholder={placeholderText} />}
      {...restProps}
    />
  );
};

CalenderComponent.propTypes = {
  selectedDate: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  minDate: PropTypes.any,
  maxDate: PropTypes.any.isRequired,
  dateFormat: PropTypes.string.isRequired,
  dateOptFormat: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  className: PropTypes.string.isRequired,
  errorType: PropTypes.any.isRequired,
  returnDateFormat: PropTypes.func.isRequired,
  checkValidStatus: PropTypes.func,
};

export default CalenderComponent;
