import React from 'react';
import PropTypes from 'prop-types';

const HeaderComponent = ({ text, headerSize, headerStyle, children }) => {
  if (headerSize === undefined || typeof headerSize === 'string') {
    headerSize = 1;
  }
  const HeaderTag = `h${headerSize}`;

  if (typeof headerStyle === "undefined") {
    headerStyle = '';
  }

  return <HeaderTag className={headerStyle}>{text} {children}</HeaderTag>;
}

HeaderComponent.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
  headerStyle: PropTypes.string.isRequired,
  headerSize: PropTypes.number.isRequired,
};

export default HeaderComponent;
