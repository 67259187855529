import React from 'react';
import PropTypes from 'prop-types';

// CustomInput component
function TextBoxComponent(props) {
  // Extract props and provide default values if not provided
  const {
    type = 'text',
    className = '',
    placeholder = '',
    value,
    onChange,
    validationRegex,
    onValidationChange,
    required = false,
    restrictInput = false,
    min = 0,
    max = 100,
    readonly = false,
    errorType = 0, //0 - No Error, 1- highlighted box,  2 - error below the textbox,3 - both 1 and 2, 4 - output box
    ...restProps
  } = props;

  
  const handleKeyDown=(e)=> {
    if (e.keyCode === 13 ) {
      e.preventDefault();
    }
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (restrictInput && validationRegex && inputValue && !validationRegex.test(inputValue)) {
      return; // Prevent updating the input value
    }
    const isInputValid = (!validationRegex || validationRegex.test(inputValue));
    const isRequiredValid = !required || (required && inputValue !== '');

    let isMinValid = true;
    let isMaxValid = true;
    if (typeof min !== 'undefined' && inputValue !== '' && inputValue.length < min) {
      isMinValid = false;
    }
    if (typeof max !== 'undefined' && inputValue !== '' && inputValue.length > max) {
      isMaxValid = false;
    }

    if (onChange) {
      onChange(event);
    }

    if (onValidationChange) {
      onValidationChange({
        isRequiredValid,
        isInputValid,
        isMinValid,
        isMaxValid
      });
    }
  };

  let updatedClassName = className;

  if (errorType === 1 || errorType === 3) {
    updatedClassName = 'red-border';
  }
  else {
    updatedClassName = 'input-text';
  }

  return (
    <input
      type={type}
      className={updatedClassName}
      placeholder={placeholder}
      value={value}
      onChange={handleInputChange}
      readOnly={readonly}
      maxLength={max}
      minLength={min}
      onKeyDown={handleKeyDown}
      {...restProps}
    />
  );
}

TextBoxComponent.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  validationRegex: PropTypes.any,
  onValidationChange: PropTypes.func,
  required: PropTypes.bool,
  restrictInput: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  readonly: PropTypes.bool,
  errorType: PropTypes.number,
};

export default TextBoxComponent;
