export const labelConstants = {
    downloadDataForOptions: [
        { label: 'Electric', value: '1' },
        { label: 'Gas', value: '2' },
        { label: 'Both', value: '3' },
    ],
    preferredDataFormatOptions: [
        { label: 'View Online', value: 'viewOnline' },
        { label: 'Microsoft Excel', value: 'excel' },
    ],
    oneBillingPreferredDataFormatOptions: [
        { label: 'View Online', value: 'viewOnline' },
        { label: 'Microsoft Excel', value: 'excel' },
        { label: 'Text File (CSV)', value: 'CSV' },
    ],
    hourlyPreferredDataFormatOptions: [
        { label: 'Microsoft Excel', value: 'excel' },
    ],
    accountVerificationDataFormatOptions: [
        { label: 'Electric', value: '1' },
    ],
    Zones: [
        { key: 'Adirondack', value: "31", isSelected: false },
        { key: 'Capital Northeast', value: "04", isSelected: false },
        { key: 'Central', value: "02", isSelected: false },
        { key: 'Frontier', value: "01", isSelected: false },
        { key: 'Genesee', value: "29", isSelected: false },
        { key: 'Utica', value: "03", isSelected: false }
    ],
    PricingMethods: [
        { label: 'Full Price', value: 'FP' },
        { label: 'Fixed Adder', value: 'FA' }
    ],
    ISOZoneRequired: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' }
    ],
    AccountNumberorESCoRequired: [
        { label: 'Account Number', value: 'accountNumber' },
        { label: 'ESCo', value: 'esco' }
    ],
    swalAlertsLabels: {
        titleSuccess: 'Success',
        titleError: 'Error!',
        titleConfirmation: 'Confirmation',
        titleWarning: 'Warning!',
        ok: 'Ok',
        errorText: 'We found some issue, Please contact system administrator',
        errorMessageForMarketAudit: 'We apologize for the inconvenience, but your account with the Energy Suppliers website is no longer active. Please call Donna Mimas at (315)428-5965 if you have any questions.',
        confirmationText: 'Are you sure you want to close the window?',
        confirmButtonText: 'Yes, close it',
        cancelButtonText: 'Cancel',
        iconSuccess: 'success',
        iconError: 'error',
        iconConfirmation: 'warning',
        cancel: 'Cancel',
        alreadyExistText: 'The four-month system average is available for this month. Would you like to override?',
        pdfAlreadyExistText: 'The Ancillary PDF is already available for [year]. Would you like to override?',
        areYouSure: 'Are you sure you want to continue?',
        yesDoIt: 'Ok',
        noCancel: 'Cancel'
    },
    toasterLabels: {
        displayText: 'Please correct the highlighted error fields',
        displayTheme: 'colored',
        displayposition: 'top-right',
        exportExcelText: 'Energy Use History is not available for this account: ',
        saveUnaccounted: 'Saved successfully.'
    },
    formLabels: {
        formSubmitLabel: 'Submit',
        energyUseHistory: 'Energy Use History',
        accountVerification: 'Account Verification',
        unaccountedforenergyEntry: 'Unaccounted For Energy Entry Screen For Admin',
        unaccountedforenergy: 'Unaccounted For Energy',
        effectiveMonthYear: 'effectiveMonthYear',
        fourMonthAvg: 'fourMonthAvg',
        ancillaryYear: 'ancillaryYear',
        ancillaryFile: 'ancillaryFile',
        internalAddDropReport: 'Internal Add/Drop Report',
        formHeadingLabel: 'Energy Market Web',
        historyRequests: 'History Requests',
        retailsSelect: 'Retail Access',
        admin: 'Admin',
        internalAdministration: 'Internal Administration',
        internalExceptions: 'Internal Exceptions',
        historyRequestsInfo: '',
        // historyRequestsInfo: 'Supply Service costs are the portion of your service for which you can shop for an alternate energy supplier, or energy service company. The services below show our costs for supply service. We encourage you to shop and compare other available supplier costs and services. Find out more about choosing another energy supplier by visiting our',
        historyRequestsInfoLink: ' Energy Choice ',
        historyRequestsInfo1: ' area.',
        energyUseHistoryInfo: 'Use this form to request energy use history data for a specific customer. If the customer authorizes you to receive the usage information, you will receive—at no charge and up to two times in any 12-month period—billing information for the most recent 24-month period as it appears on our portion of the bill. You will be subject to a charge of $15 for each request beyond the second request in any 12-month period.',
        hourlyIntervalHistoryInfo: 'Use this form to access the most recent 24 months of unadjusted hourly interval data for a customer (for which you have been authorized to receive usage information). You can access this information at no charge and up to twice in any 12-month period',
        lightingHistoryInfo: 'Use this form to request lighting history data for a specific customer. If you have been authorized by the customer, you will receive billing information for the most recent 24-months. You may receive this usage information for no charge up to two times in any 12-month period. There will be a $15 charge for each request beyond the second request in any 12-month period.',
        billingStatementInfo: 'List information as it appears on the customer\'s billing statement.',
        retailsSelectInfo: 'Information entered after 5:55 p.m. will have the next day\'s insert date',
        customerInformation: 'Customer Information',
        fullPriceORFixedAdder: 'Full Price/Fixed Adder: ($) * ',
        perKWH: 'per kWh',
        hourlyIntervalDataHistory: 'Hourly Interval Data History',
        lightingHistory: 'Lighting History',
        accountNumber: 'Account Number',
        multipleSelectionLabel: 'Selected Options:',
        customerName: 'Customer Name',
        singleSelectionLabel: 'Selected Option:',
        serviceStreetAddress: 'Service Street Address',
        effectiveDateInfo: 'Must match the effective date previously submitted.',
        address: 'Address',
        addressLine1: 'Address Line 1',
        addressLine2: 'Address Line 2',
        city: 'City',
        state: 'State',
        zipCode: 'Zip Code',
        downloadDataFor: 'Download data for',
        preferredDataFormat: 'Select how you would like to view or download the data',
        requiredFields: 'Required Fields',
        compulsoryRequiredFields: 'Date of Birth or Driver\'s License# is required',
        exceptions: 'Exceptions',
        startDate: 'Start Date',
        endDate: 'End Date',
        enrollmentsdropschanges: 'Enrollments/Drops/Changes',
        discontinuedservices: 'Discontinued Services',
        billingdeterminants: 'Billing Determinants',
        lightingbilldeterminants: 'Lighting Bill Determinants',
        onebillpricing: 'One Bill Pricing',
        eligibletodrop: 'Eligible To Drop',
        noDataFound: 'is not available for this account: ',
        voltageLevel: 'Voltage Level',
        isoZone: 'ISO Zone',
        taxDistrict: 'Tax District',
        tableViewHeading: 'You requested to view this data online, but you also have an option to download this data to a spreadsheet, located at the bottom of this window.',
        records: 'Records',
        electricHistory: 'Electric History',
        gasHistory: 'Gas History',
        palHistory: 'Electric Lighting History',
        palHistoryData: 'Lighting Data',
        backToSelectionPage: 'Back To Selection',
        excelBtn: 'Download As Excel',
        weAreSorryNorecordsFound: "We're sorry, but no records were found.",
        weAreSorryNorecordsFoundBillingDeterminants: "We're sorry, but no records were found for Billing Determinants.",
        noRecordsAvailable: "No Records Available",
        billingdeterminantsNoDataFound: 'No Interval Billing data is available.',
        viewOnline: 'viewOnline',
        excel: 'excel',
        excelCustomerInfo: 'You requested Energy Use History for: ',
        accountVerificationExcelInfo: 'You requested Account Verification Report for: ',
        excelDownloadInfo: 'Please use the following link(s) to download the data to your computer.',
        excelDiscotinuedServiceInfo: 'You requested Discontinued Services for: ',
        hourlyExcelCustomerInfo: 'You requested hourly interval data history for: ',
        hourlyExcelDownloadInfo: 'Please use the following link to download the data to your computer.',
        hourlyExcelDownloadLink: 'Hourly Interval Data History (Metered)',
        hourlyNodataFound: 'There is no interval data history (Metered) for this account.',
        gasNoDataFound: 'There is no gas history for this account.',
        electricNoDataFound: 'There is no electric history for this account.',
        hourlyExcelDownloadInfo1: 'Please Note: When viewing the data in Excel, it will be rounded to two decimals. To view the actual prices, format the cells to five decimal places.',
        supplySelectInfo1: '',
        // supplySelectInfo1: 'Supply Service costs are the portion of your service for which you can shop for an alternate energy supplier, or energy service company. The services below show our costs for supply service. We encourage you to shop and compare other available supplier costs and services. Find out more about choosing another energy supplier by visiting our',
        supplySelectarea: 'area.',
        energyChoice: 'Energy Choice',
        naturalGasProgramManual: 'Natural Gas Program Manual',
        naturalGasDownloadInfo: 'Download the Natural Gas Manual using the link below for your reference.',
        downloadPDF: 'Download PDF',
        billingMaintenance: 'Billing Maintenance',
        interruptibleRates: 'Interruptible Rates',
        addNewPrice: 'Add a New Price',
        changePrice: 'Change a Price',
        addProgramPrice: 'Use this form to change an existing price in a pricing group.',
        addProgramContact: 'To add a new pricing group, please contact us.',
        correctPrice: 'Correct a Price',
        correctProgramPrice: 'Use this form to correct a price in a pricing group that is not yet effective.',
        confirmation: 'Confirmation',
        supplySelectConfirmationInfo1: 'We have processed your request for program number',
        supplySelectConfirmationInfo2: 'We appreciate your business!',
        dailyReports: "Daily Reports",
        dateRangeSelectionInfoText: "To reduce wait times, limit date range to 2-3 days per query or download data without previewing online.",
        selectdateRange: "Select Date Range",
        Past30Days: "(Within the past 30 days)",
        fromDate: "From Date",
        toDate: "To Date",
        reportEndDate: 'Report End Date:',
        accountVerificationReport: 'Account Verification Report',
        spreadsheetData: 'Spreadsheet Data',
        dataAvailableFfomSept: "Data available from Septmember 1, 1998",
        accountVerificationwarningText: 'Account Verification data is available for your electricity usage only.',
        accountVerificationInfoText: 'To reduce your waiting time, consider limiting your date range selections to two- or three-day periods per inquiry, or download the data without first viewing online.',
        lighteningBillingDeterminantsText: 'Lighting Bill Determinants data is available for your eletricity usage only.',
        unaccountedForEnergyHeadline1: 'Four Month System Average \'Unaccounted For Energy\'',
        unaccountedForEnergyHeadline2: 'Beginning May 2005, per Rule 39.18.1.2, National Grid will use the Four Month System Avarage Unaccounted For Energy factor for Rule 46 Electric Supply Cost.',
        unaccountedForEnergyData1: "'Unaccounted For Energy' Statistics",
        unaccountedForEnergyData2: '(Valid through April 2005)',
        unaccountedForEnergyData3: "These monthly files show percentage of energy in each load zone that is considered to be 'unaccounted for'. This percentage represents the difference between the load provided by the NYISO in each subzone and the metered billing data for all load serving entities, including National Grid's New York service territory, in a subzone grossed up for losses as reflected in Rule 39.18.",
        unaccountedForEnergyData4: 'Links noted by "(pdf)" are in PDF format and support Adobe Acrobat 5.0 and later. Download Adobe Reader, free software to view and print PDF files.',
        unaccountedForEnergyData5: 'This will be updated monthly when numbers are available.',
        ESCo: "ESCo",
        fromDateAvailable: "From Date (Data available form September 1, 1998)",
        switchView: "Switch to another view:",
        ancillaryServiceCharges: 'Ancillary Service Charges',
        ancillaryServiceChargesAdmin: 'Ancillary Service Charges For Admin',
        ancillaryserviceData1: 'These monthly files show the amount of ancillary service charges that are inluded in the calculation of our eletricity supply charges in accordance with Rule 46 of PSC No. 220',
        ancillaryserviceData2: 'This will be updated monthly when numbers are available.',
        ancillaryserviceData3: 'Links noted by "(pdf)" are in PDF format and support Adobe Acrobat 5.0 and later. Download Adobe Reader, free software to view and print PDF files.',
        marketeraudit: 'Marketer Audit',
        marketeraudit1: 'To audit an ESCo\'s account, please select from the list and submit.',
        marketeraudit2: 'Once you log into an ESCo\'s account, you will no longer be able to use your Administrative menu on the prior page. You can log back into your own account, through the dropdown above.',
        marketeraudit3: 'If you log off from within a ESCo\'s account, this page will no longer be active.',
        selectSupplier: 'Select Supplier *',
        switchViewtwo: "Switch View:",
        internalAdminstrator: "Internal Administration",
        marketerEnrollments: "Marketer Enrollments",
        tsaLink: "Transportation Services Application",
        tsaLinkTextl: "Clicking the link below will open a new page, directing you into the Transportation Services Application login page.",
        tsaSubmit: "Go to TSA Log in Page",
        originalTsaLink: "https://www9.nationalgridus.com/niagaramohawk/energy_supplier/mktctrapps/SmarTRAC/tnlogin.asp",
        termOfRate: "Term of Rate",
        renewalDate: "Renewal Date",
        relatedInformation: 'Related Information',
        taxDistrictNumbersInformation: "For translation of Tax District Numbers. Click the link below.",
        taxDistrictNumbers: "Tax District Numbers",
        taxDistrictNumberLink: "localhost:9090",
        ancillaryservice2024pdf: "Charges for 2024 (pdf)",
        ancillaryservice2023pdf: "Charges for 2023 (pdf)",
        ancillaryservice2022pdf: "Charges for 2022 (pdf)",
        ancillaryservice2021pdf: "Charges for 2021 (pdf)",
        ancillaryservice2020pdf: "Charges for 2020 (pdf)",
        ancillaryservice2019pdf: "Charges for 2019 (pdf)",
        ancillaryservice2018pdf: "Charges for 2018 (pdf)",
        ancillaryservice2017pdf: "Charges for 2017 (pdf)",
        ancillaryservice2016pdf: "Charges for 2016 (pdf)",
        ancillaryservice2015pdf: "Charges for 2015 (pdf)",
        ancillaryservice2014pdf: "Charges for 2014 (pdf)",
        ancillaryservice2013pdf: "Charges for 2013 (pdf)",
        ancillaryservice2012pdf: "Charges for 2012 (pdf)",
        ancillaryservice2011pdf: "Charges for 2011 (pdf)",
        ancillaryservice2010pdf: "Charges for 2010 (pdf)",
        ancillaryservice2009pdf: "Charges for 2009 (pdf)",
        ancillaryservice2008pdf: "Charges for 2008 (pdf)",
        ancillaryservice2007pdf: "Charges for 2007 (pdf)",
        ancillaryservice2006pdf: "Charges for 2006 (pdf)",
        ancillaryservice2005pdf: "Charges for 2005 (pdf)",
        ancillaryservice2004pdf: "Charges for 2004 (pdf)",
        ufe2005: "UFE for 2005 (pdf)",
        ufe2004: "UFE for 2004 (pdf)",
        ufe2003: "UFE for 2003 (pdf)"
    },
    formPlaceholders: {
        statePlaceholder: 'Select  State',
        MonthFirstWithSlash: 'MM/DD/YYYY',
        ESCo: 'Select ESCo'
    },
    errorMessages: {
        invalid: 'Invalid',
        accountNumberRequired: 'Account Number is Required',
        serviceStreetAddress: 'Service Street Address is required',
        zipCodeRequired: 'Zip Code is required',
        stateRequired: 'State is required',
        selectState: 'Please Select State',
        downloadDataFor: 'Please select any option',
        selectValidDate: 'Please Select Valid Date',
        addressValid: 'Please enter a valid Address',
        zipCodeValid: 'Please enter a valid Zipcode',
        cityValid: 'Please enter a valid city',
        accountNumberValid: 'Please enter a valid Account Number',
        customerNameValid: 'Please enter a valid Name',
        min: 'Minimum length Required',
        max: 'Maximum length Exceeded',
        valid: 'Valid',
        inValid: 'Invalid',
        fromDateRequired: 'From Date is required',
        toDateRequired: 'To Date is required',
        fromDateGreaterThanToDate: 'From Date cannot be greater than To Date',
        toDateLessThanFromDate: 'To Date cannot be less than From Date',
        startDateRequired: 'Start Date is required',
        endDateRequired: 'End Date is required',
        startDateGreaterThanToDate: 'Start Date cannot be greater than End Date',
        endDateLessThanStartDate: 'End Date cannot be less than Start Date',
        programNumberRequired: 'Program Number is Required',
        addProgramNumberValid: 'Program number should be a 3 position numeric between 801 and 813',
        correctProgramNumberValid: 'Program number should be a 3 position numeric between 801 and 819',
        changeProgramNumberValid: 'Program number should be a 3 position numeric and be 813 or between 820 and 833',
        pricingGroupRequired: 'Pricing Group is Required',
        pricingGroupValid: 'Pricing group should be a 2 position alphanumeric, and cannot be set to 00 or NC',
        flatRatePerThermRequired: 'Flat Rate Per Therm ($) is Required',
        flatRatePerThermValid: 'Flat Rate Per Therm should be a number between zero and 4.0',
        reportEndDateRequired: 'Report End Date is required',
        pricingMethodValid: 'Please select a pricing method',
        fullPriceRequired: 'Full price is Required',
        fullPriceValid: 'Full price should be between .00 and .50',
        fixedPriceValid: 'Fixed adder should be between -.03 and .10',
        isZoneRequired: 'Please answer the ISO zone required question',
        zonesRequired: 'Please check at least one ISO zone or check No for ISO Zone Required',
        parseDate: 'This data is not available prior to',
        userIdRequired: "User ID is required",
        passwordRequired: "Password is required",
        FullFixedValid: 'Full price/Fixed adder must be 8 decimal places or less',
        flatRateDecimalValid: 'Flat Rate Per Therm must be 8 decimal places or less',
        errorescoRequired: 'Please Select ESCo',
        FullPriceInvalid: 'Full price should be a numeric',
        effectiveDateRequired: 'Effective date is reqired',
        fourMonthAvgRequired: 'Four Month System Average is reqired',
        fourMonthAvgInvalid: 'Invalid input. Please enter a valid number',
        yearRequired: 'Ancillary Year is reqired',        
        ancillaryFileRequired: 'Ancillary File is reqired'
    },
    formFields: {
        customerName: 'customerName',
        accountNumber: 'accountNumber',
        serviceStreetAddress: 'serviceStreetAddress',
        city: 'city',
        zipCode: 'zipCode',
        state: 'state',
        downloadDataFor: 'downloadDataFor',
        preferredDataFormat: 'preferredDataFormat',
        programNumber: 'programNumber',
        pricingGroup: 'pricingGroup',
        flatRatePerTherm: 'flatRatePerTherm',
        effectiveDate: 'effectiveDate',
        reportEndDate: 'reportEndDate',
        fullPrice: 'fullPrice',
        pricingMethod: 'pricingMethod',
        isZoneRequired: 'isZoneRequired',
        zones: 'zones',
        FromDate: 'fromDate',
        ToDate: 'toDate',
        esco: 'esco',
        fromDate: 'fromDate',
        toDate: 'toDate',
        totalRecords: 'Total records: '
    },
    discontinueformFields: {
        FromDate: 'fromDate',
        ToDate: 'toDate',
        downloadDataFor: 'downloadDataFor',
        preferredDataFormat: 'preferredDataFormat'
    },
    exceptionsformFields: {
        FromDate: 'fromDate',
        ToDate: 'toDate',
        downloadDataFor: 'downloadDataFor',
        preferredDataFormat: 'preferredDataFormat'
    },
    billingdeterminantsformFields: {
        FromDate: 'fromDate',
        ToDate: 'toDate',
        downloadDataFor: 'downloadDataFor',
        preferredDataFormat: 'preferredDataFormat'
    },
    lighteningbilldeterminantsformFields: {
        FromDate: 'fromDate',
        ToDate: 'toDate',
        downloadDataFor: 'downloadDataFor',
        preferredDataFormat: 'preferredDataFormat'
    },
    enrollmentsDropsformFields: {
        FromDate: 'fromDate',
        ToDate: 'toDate',
        downloadDataFor: 'downloadDataFor',
        preferredDataFormat: 'preferredDataFormat'
    },
    selectedErrorType: 1, //0 - No Error, 1 - highlighted box,  2 - error below the textbox, 3 - both 1 and 2, 4 - output box
    calenderStartDate: '1900-01-01',
    loginPagelabels: {
        nationalGridLabel: 'nationalgrid',
        nyEnergySupply: 'NY Energy suppliers',
        energySupplietLogin: 'Energy supplier Login',
        enterLoginDetails: 'Enter the following details to securely log into your account',
        userId: 'User ID',
        password: 'Password',
        needHelp: 'Need help?',
        contactus: 'Contact US',
        disclaimer: 'Disclaimer',
        login: 'Login'
    },
    disclaimerLabels: {
        ftcUrl: 'http://www.ftc.gov/',
        point1: 'National Grid makes no guarantee, warranty or representation, expressed or implied (including implied warranties of merchantability and fitness for a particular purpose), with regard to the accuracy, completeness, usefulness, timeliness or suitability of the information being provided.',
        point2: 'For more information regarding online privacy issues, please visit the ',
        point3: 'Federal Trade Commission',
        point4: 'This website is intended solely for the use of customers and their authorized representatives. Information regarding customer usage is not a substitute for any National Grid bill for services provided. National Grid’s bill shall be the official record of any services provided to the customer and of the charges for such services.',
        point5: "In the event of a conflict between the customer's bill and the information provided on National Grid’s website, the bill shall govern. If you have any problems or questions concerning a bill or services provided, please telephone Customer Service at 1-800-642-4272, or write us at 300 Erie Blvd West, Syracuse, New York 13202.",
        point6: 'It is expressly understood and agreed that neither National Grid nor its directors, officers, employees or agents shall assume any liability or responsibility for any damages or claims resulting from any use or disclosure of the information being provided except for any damages or losses caused by the gross negligence or intentional misconduct of the Company.',
        point7: 'The access and use of any provided information shall be governed by the laws of the State of New York.',
        point8: 'Please note that by using this link, you will be leaving our website.  Please review our Privacy Policy for more information.'
    },
    loginPageFields: {
        userId: "userId",
        password: "password"
    },
    viewTypeOptions: {
        list: "list",
        form: "form"
    },
    headerUrls: {
        GasEmergenciesURL: 'https://www.nationalgridus.com/Default.aspx?r.u=%2fNatural-Gas-Safety%2freport-a-gas-emergency%3f',
        PowerOutagesURL: 'https://www1.nationalgridus.com/outages',
        ContactUsURL: 'https://www.nationalgridus.com/Default.aspx?r.u=%2fContact-Us%3f'
    },
    claimsKeys: {
        aud: "aud",
        iss: "iss",
        iat: "iat",
        nbf: "nbf",
        exp: "exp",
        name: "name",
        preferred_username: "preferred_username",
        nonce: "nonce",
        oid: "oid",
        tid: "tid",
        upn: "upn",
        email: "email",
        acct: "acct",
        sid: "sid",
        sub: "sub",
        ver: "ver",
        auth_time: "auth_time",
        at_hash: "at_hash",
        uti: "uti",
        rh: "rh"
    },
    claimsDescriptions: {
        aud: "Identifies the intended recipient of the token. In ID tokens, the audience is your app's Application ID, assigned to your app in the Azure portal.",
        iss: "Identifies the issuer, or authorization server that constructs and returns the token. It also identifies the Azure AD tenant for which the user was authenticated. If the token was issued by the v2.0 endpoint, the URI will end in /v2.0. The GUID that indicates that the user is a consumer user from a Microsoft account is 9188040d-6c67-4c5b-b112-36a304b66dad.",
        iat: "Issued At indicates when the authentication for this token occurred.",
        nbf: "The nbf (not before) claim identifies the time (as UNIX timestamp) before which the JWT must not be accepted for processing.",
        exp: "The exp (expiration time) claim identifies the expiration time (as UNIX timestamp) on or after which the JWT must not be accepted for processing. It's important to note that in certain circumstances, a resource may reject the token before this time. For example, if a change in authentication is required or a token revocation has been detected.",
        name: "The name claim provides a human-readable value that identifies the subject of the token. The value isn't guaranteed to be unique, it can be changed, and it's designed to be used only for display purposes. The profile scope is required to receive this claim.",
        preferred_username: "The primary username that represents the user. It could be an email address, phone number, or a generic username without a specified format. Its value is mutable and might change over time. Since it is mutable, this value must not be used to make authorization decisions. It can be used for username hints, however, and in human-readable UI as a username. The profile scope is required in order to receive this claim.",
        nonce: "The nonce matches the parameter included in the original /authorize request to the IDP. If it does not match, your application should reject the token.",
        oid: "The oid (user’s object id) is the only claim that should be used to uniquely identify a user in an Azure AD tenant. The token might have one or more of the following claim, that might seem like a unique identifier, but is not and should not be used as such.",
        tid: "The tenant ID. You will use this claim to ensure that only users from the current Azure AD tenant can access this app.",
        upn: "(user principal name) – might be unique amongst the active set of users in a tenant but tend to get reassigned to new employees as employees leave the organization and others take their place or might change to reflect a personal change like marriage.",
        email: "Email might be unique amongst the active set of users in a tenant but tend to get reassigned to new employees as employees leave the organization and others take their place.",
        acct: "Available as an optional claim, it lets you know what the type of user (homed, guest) is. For example, for an individual’s access to their data you might not care for this claim, but you would use this along with tenant id (tid) to control access to say a company-wide dashboard to just employees (homed users) and not contractors (guest users).",
        sid: "Session ID, used for per-session user sign-out.",
        sub: "The principal about which the token asserts information, such as the user of an application. This value is immutable and can't be reassigned or reused. It can be used to perform authorization checks safely, such as when the token is used to access a resource. By default, the subject claim is populated with the object ID of the user in the directory",
        ver: "Version of the token issued by the Microsoft identity platform",
        auth_time: "The time at which a user last entered credentials, represented in epoch time. There is no discrimination between that authentication being a fresh sign-in, a single sign-on (SSO) session, or another sign-in type.",
        at_hash: "An access token hash included in an ID token only when the token is issued together with an OAuth 2.0 access token. An access token hash can be used to validate the authenticity of an access token"
    }
};
