import React, { createContext, useReducer } from 'react';
import {setCusEscoId} from '../utils/accessRolesChecks';

const sessionData = sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')) : null
const loggedUserData = sessionData ? {
  message: sessionData.message,
  roleList: sessionData.roleList,
  userDetails: sessionData.userDetails,
  statusCode: sessionData.statusCode,
  adminId: sessionData.adminId
} : null
const initialState = {
  userData: loggedUserData
}


export const AppReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_LOGGED_USER_DATA':
      const loggedUserData = { ...action.payload, adminId: "" };
      sessionStorage.setItem('userData', JSON.stringify(loggedUserData));
      setCusEscoId(loggedUserData?.userDetails?.custEscoId);
      return {
        ...state,
        userData: loggedUserData,
      };
    case 'REMOVE_LOGGED_USER_DATA':
      return {
        ...state,
        userData: null,
      };
    case 'UPDATED_ROLES_DATA':
      let data = { ...action.payload, adminId: state.userData.userDetails.userId };
      sessionStorage.setItem('userData', JSON.stringify(data));
      setCusEscoId(data?.userDetails?.custEscoId);
      return {
        ...state,
        userData: data
      };
    default:
      return state;
  }
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
