import React from 'react';
import PropTypes from 'prop-types';

const RadioButtonComponent = (props) => {
    const {
        type = 'radio',
        name = '',
        value,
        onChange,
        options = [],
        containerClassname = '',
        radiobuttonlistClassname = '',
        inputClassname = '',
        labelClassname = '',
        ...restProps
    } = props;

    const handleRadioChange = (event) => {
        const selectedValue = event.target.value;

        if (onChange) {
            onChange(selectedValue);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
    }

    return (
        <div>
            <div className={containerClassname}>
                {options.map((option, index) => (
                    <div className={radiobuttonlistClassname} key={index}>
                        <input className={inputClassname}
                            type={type}
                            name={name}
                            id={option.value}
                            value={option.value}
                            checked={value === option.value}
                            onChange={handleRadioChange}
                            onKeyDown={handleKeyDown}
                            {...restProps}
                        />
                        <label className={labelClassname} htmlFor={option.value}>
                            {option.label}
                        </label>
                    </div>))}
            </div>
        </div>
    );
}

RadioButtonComponent.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    containerClassname: PropTypes.string.isRequired,
    radiobuttonlistClassname: PropTypes.string.isRequired,
    inputClassname: PropTypes.string.isRequired,
    labelClassname: PropTypes.string.isRequired
};

export default RadioButtonComponent;