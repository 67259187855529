import React, { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import beginningArrows from '../../assests/images/beginningArrows.gif'
import endArrows from '../../assests/images/endArrows.gif'
import nextArrow from '../../assests/images/nextArrow.gif'
import previousArrow from '../../assests/images/previousArrow.gif'
import './FormViewPaginationComponent.css'
import { Form } from 'react-bootstrap';
import LabelControl from '../label/LabelComponent';
import TextBoxComponent from '../textbox/TextBoxComponent';

function FormViewPaginationComponent({ data, pageChangeHandler, currentPage, totalRows, rowsPerPage }) {

  const columns = useMemo(() => {
    if (data && data.length > 0) {
      const keys = Object.keys(data[0]);
      return keys.map((key) => ({
        Header: key.toUpperCase(),
        accessor: key,
      }));
    }
    return [];
  }, [data]);

  const {
    state: { pageIndex, pageSize } } = useTable({
      columns,
      data,
      initialState: { pageIndex: currentPage - 1, pageSize: rowsPerPage },
    },
      usePagination
    );

  const onNextPage = () => pageChangeHandler(currentPage + 1);
  const onPrevPage = () => pageChangeHandler(currentPage - 1);
  const onGotoPage = (pageNo) => pageChangeHandler(pageNo + 1);
  const pageNumbers = [];
  const maxPageButtons = 6;
  const noOfPages = Math.ceil(totalRows / rowsPerPage);
  let startPage = 0;
  let endPage = 0;
  if (noOfPages <= maxPageButtons) {
    endPage = noOfPages - 1;
  } else {
    if (pageIndex <= Math.floor(maxPageButtons / 2)) {
      endPage = maxPageButtons - 1;
    } else if (pageIndex >= noOfPages - Math.floor(maxPageButtons / 2)) {
      startPage = noOfPages - maxPageButtons;
      endPage = noOfPages - 1;
    } else {
      startPage = pageIndex - Math.floor(maxPageButtons / 2);
      endPage = pageIndex + Math.floor(maxPageButtons / 2);
    }
  }
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  return (
    <div>
      {/* Pagination controls */}
      <div className="paginatorLinks">
        <span className='show-entities'>
          Showing &nbsp;
          <b>{Math.min((pageIndex + 1) * pageSize, totalRows)}</b> of <b>{totalRows}</b> record
        </span>
        <div style={{ float: 'right' }}>
          <a href={() => false} onClick={() => onGotoPage(0)} onKeyDown={() => onGotoPage(0)} disabled={!canGoBack} className={canGoBack ? '' : 'disabled-link'}>
            <img src={beginningArrows} alt='beginningArrows' />
          </a>
          <a href={() => false} onClick={() => onPrevPage()} onKeyDown={() => onPrevPage()} disabled={!canGoBack} className={canGoBack ? '' : 'disabled-link'}>
            <img src={previousArrow} alt='previousArrow' />
          </a>
          {pageNumbers.length > 1 && pageNumbers.map((page) => (
            <a href={() => false}
              key={page}
              onClick={() => onGotoPage(page)}
              onKeyDown={() => onGotoPage(page)}
              className={pageIndex === page ? 'active' : ''}
            >
              {page + 1}
            </a>
          ))}
          <a href={() => false} onClick={() => onNextPage()} onKeyDown={() => onNextPage()} disabled={!canGoNext} className={canGoNext ? '' : 'disabled-link'}>
            <img src={nextArrow} alt='nextArrow' />
          </a>
          <a href={() => false} onClick={() => onGotoPage(noOfPages - 1)} onKeyDown={() => onGotoPage(noOfPages - 1)} disabled={!canGoNext} className={canGoNext ? '' : 'disabled-link'}>
            <img src={endArrows} alt='endArrows' />
          </a>
        </div>
      </div>
      <div>
        <Form>
          {data.map((item, index) => (
            <div key={index} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              {Object.keys(item).map((sectionKey) => (
                <div key={sectionKey} >
                  <LabelControl text={sectionKey} textStyle="form-label-heading" />
                  <Form.Group controlId={`${sectionKey}-${index}`} className="form-group">
                    <div className="row">
                      {Object.entries(item[sectionKey]).map(([key, value]) => (
                        <div key={`${sectionKey}-${key}-${index}`} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="NG-fieldSet">
                            <Form.Group controlId={`${key}-${index}`} className="form-group">
                              <LabelControl text={key} htmlFor={key} textStyle="custom-label" />
                              <TextBoxComponent type="text" name={key}
                                value={value} />
                            </Form.Group>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Form.Group>
                </div>
              ))}
            </div>
          ))}
        </Form>
      </div>
      {/* Pagination controls */}
      <div className="paginatorLinks">
        <span className='show-entities'>
          Showing &nbsp;
          <b>{Math.min((pageIndex + 1) * pageSize, totalRows)}</b> of <b>{totalRows}</b> record
        </span>
        <div style={{ float: 'right' }}>
          <a href={() => false} onClick={() => onGotoPage(0)} onKeyDown={() => onGotoPage(0)} disabled={!canGoBack} className={canGoBack ? '' : 'disabled-link'}>
            <img src={beginningArrows} alt='beginningArrows' />
          </a>
          <a href={() => false} onClick={() => onPrevPage()} onKeyDown={() => onPrevPage()} disabled={!canGoBack} className={canGoBack ? '' : 'disabled-link'}>
            <img src={previousArrow} alt='previousArrow' />
          </a>
          {pageNumbers.length > 1 && pageNumbers.map((page) => (
            <a href={() => false}
              key={page}
              onClick={() => onGotoPage(page)}
              onKeyDown={() => onGotoPage(page)}
              className={pageIndex === page ? 'active' : ''}
            >
              {page + 1}
            </a>
          ))}
          <a href={() => false} onClick={() => onNextPage()} onKeyDown={() => onNextPage()} disabled={!canGoNext} className={canGoNext ? '' : 'disabled-link'}>
            <img src={nextArrow} alt='nextArrow' />
          </a>
          <a href={() => false} onClick={() => onGotoPage(noOfPages - 1)} onKeyDown={() => onGotoPage(noOfPages - 1)} disabled={!canGoNext} className={canGoNext ? '' : 'disabled-link'}>
            <img src={endArrows} alt='endArrows' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default FormViewPaginationComponent;
