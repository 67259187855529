import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { regexPatterns } from '../../constants';
import beginningArrows from '../../assests/images/beginningArrows.gif'
import endArrows from '../../assests/images/endArrows.gif'
import nextArrow from '../../assests/images/nextArrow.gif'
import previousArrow from '../../assests/images/previousArrow.gif'
import './TablePagination.css'
function TablePaginationComponent({ data, customColumns, headerText, headerClassName, tableClassName, tableHeadingClassName, tableDataClassName, footerClassName, footerText, columnsToFormat, formatSymbol, columnsToSum,
  pageChangeHandler, currentPage, totalRows, rowsPerPage }) {

  const columns = React.useMemo(
    () => {
      if (!customColumns || customColumns.length === 0) {
        if (!data || data.length === 0) {
          return [];
        }

        const firstDataRow = data[0];
        return Object.keys(firstDataRow).map((key) => ({
          Header: key
            .split(' ')
            .map((word) => {
              return word.replace(regexPatterns.UpperCase, ' $1');
            })
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
          accessor: key.replace(/\./g, ''),
          Cell: (row) => {
            if (columnsToFormat && columnsToFormat.includes(key.toLowerCase())) {
              const formattedValue = Number(row.value).toFixed(2);
              return `${formatSymbol}${formattedValue}`;
            }
            return row.value;
          },
        }));
      }
      else {
        return customColumns;
      }
    }, [data, customColumns, columnsToFormat, formatSymbol]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow, // Pagination specific properties
    state: { pageIndex, pageSize } } = useTable({
      columns,
      data,
      initialState: { pageIndex: currentPage - 1, pageSize: rowsPerPage },
    },
      usePagination
    );

  const balanceSum = React.useMemo(() => {
    if (!Array.isArray(columnsToSum) || columnsToSum.length === 0) {
      return 0;
    }
    return data.reduce((sum, row) => {
      const columnSum = columnsToSum.reduce((colSum, column) => colSum + row[column], 0);
      return sum + columnSum;
    }, 0);
  }, [data, columnsToSum]);
  const onNextPage = () => pageChangeHandler(currentPage + 1);
  const onPrevPage = () => pageChangeHandler(currentPage - 1);
  const onGotoPage = (pageNo) => pageChangeHandler(pageNo + 1);
  const pageNumbers = [];
  const maxPageButtons = 6;
  const noOfPages = Math.ceil(totalRows / rowsPerPage);
  let startPage = 0;
  let endPage = 0;
  if (noOfPages <= maxPageButtons) {
    startPage = 0;
    endPage = noOfPages - 1;
  } else {
    if (pageIndex <= Math.floor(maxPageButtons / 2)) {
      startPage = 0;
      endPage = maxPageButtons - 1;
    } else if (pageIndex >= noOfPages - Math.floor(maxPageButtons / 2)) {
      startPage = noOfPages - maxPageButtons;
      endPage = noOfPages - 1;
    } else {
      startPage = pageIndex - Math.floor(maxPageButtons / 2);
      endPage = pageIndex + Math.floor(maxPageButtons / 2);
    }
  }
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);
  return (
    <div>
      {/* Pagination controls */}
      <div className="paginatorLinks paginatorLinks-top">
        <span className='show-entities'>
          Showing <b>{pageIndex * pageSize + 1}</b> to{' '}
          <b>{Math.min((pageIndex + 1) * pageSize, totalRows)}</b> of <b>{totalRows}</b> records
        </span>
        <div style={{ float: 'right' }}>
          <a href={() => false} onClick={() => onGotoPage(0)} onKeyDown={() => onGotoPage(0)} disabled={!canGoBack} className={canGoBack ? '' : 'disabled-link'}>
            <img src={beginningArrows} alt='beginningArrows' />
          </a>
          <a href={() => false} onClick={() => onPrevPage()} onKeyDown={() => onPrevPage()} disabled={!canGoBack} className={canGoBack ? '' : 'disabled-link'}>
            <img src={previousArrow} alt='previousArrow' />
          </a>
          {pageNumbers.length > 1 && pageNumbers.map((page) => (
            <a href={() => false}
              key={page}
              onClick={() => onGotoPage(page)}
              onKeyDown={() => onGotoPage(page)}
              className={pageIndex === page ? 'active' : ''}
            >
              {page + 1}
            </a>
          ))}
          <a href={() => false} onClick={() => onNextPage()} onKeyDown={() => onNextPage()} disabled={!canGoNext} className={canGoNext ? '' : 'disabled-link'}>
            <img src={nextArrow} alt='nextArrow' />
          </a>
          <a href={() => false} onClick={() => onGotoPage(noOfPages - 1)} onKeyDown={() => onGotoPage(noOfPages - 1)} disabled={!canGoNext} className={canGoNext ? '' : 'disabled-link'}>
            <img src={endArrows} alt='endArrows' />
          </a>
        </div>
      </div>
      <div className="table-section">
        <table {...getTableProps()} className={tableClassName}>
          <thead>
            {headerText && (
              <tr>
                <th colSpan={columns.length} className={headerClassName}>
                  {headerText}
                </th>
              </tr>
            )}
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className={tableHeadingClassName}{...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td className={tableDataClassName}{...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
          {footerText && (
            <tfoot className={footerClassName}>
              <tr>
                <td colSpan={columns.length - 1}>{footerText}</td>
                <td>
                  {isNaN(balanceSum) || balanceSum === null ? '0.00' : (formatSymbol ? `${formatSymbol}${balanceSum.toFixed(2)}` : balanceSum.toFixed(2))}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      {/* Pagination controls */}
      <div className="paginatorLinks  paginatorLinks-bottom">
        <span className='show-entities'>
          Showing <b>{pageIndex * pageSize + 1}</b> to{' '}
          <b>{Math.min((pageIndex + 1) * pageSize, totalRows)}</b> of <b>{totalRows}</b> records
        </span>
        <div style={{ float: 'right' }}>
          <a href={() => false} onClick={() => onGotoPage(0)} onKeyDown={() => onGotoPage(0)} disabled={!canGoBack} className={canGoBack ? '' : 'disabled-link'}>
            <img src={beginningArrows} alt='beginningArrows' />
          </a>
          <a href={() => false} onClick={() => onPrevPage()} onKeyDown={() => onPrevPage()} disabled={!canGoBack} className={canGoBack ? '' : 'disabled-link'}>
            <img src={previousArrow} alt='previousArrow' />
          </a>
          {pageNumbers.length > 1 && pageNumbers.map((page) => (
            <a href={() => false}
              key={page}
              onClick={() => onGotoPage(page)}
              onKeyDown={() => onGotoPage(page)}
              className={pageIndex === page ? 'active' : ''}
            >
              {page + 1}
            </a>
          ))}
          <a href={() => false} onClick={() => onNextPage()} onKeyDown={() => onNextPage()} disabled={!canGoNext} className={canGoNext ? '' : 'disabled-link'}>
            <img src={nextArrow} alt='nextArrow' />
          </a>
          <a href={() => false} onClick={() => onGotoPage(noOfPages - 1)} onKeyDown={() => onGotoPage(noOfPages - 1)} disabled={!canGoNext} className={canGoNext ? '' : 'disabled-link'}>
            <img src={endArrows} alt='endArrows' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default TablePaginationComponent;
